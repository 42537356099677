import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { useEffect } from 'preact/hooks';
import { onAuthStateChanged, User } from 'firebase/auth';
import { signal } from "@preact/signals";

export const firebaseConfig = {
    apiKey: "AIzaSyBSncbAHog04TjJriQFCxfqGm7VzDkV_SY",
    authDomain: "phays-17a2f.firebaseapp.com",
    projectId: "phays-17a2f",
    storageBucket: "phays-17a2f.appspot.com",
    messagingSenderId: "734858996146",
    appId: "1:734858996146:web:463f5e3be351804fcf9cab"
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export interface AuthInfo {
    auth: Auth,
    user: User | null,
    loading: boolean,
}


const authInfoSig = signal<AuthInfo>({
    user: null,
    auth,
    loading: true,
});

export function useAuth() {
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            authInfoSig.value = { user, auth, loading: false };
        });

        return () => unsubscribe();
    }, []);
    return authInfoSig;
}
