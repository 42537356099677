import { Header } from "../common/header";

export function App() {
    return (
        <>
            <Header></Header>
            <div class="container">
                <div>Phays Admin</div>
            </div>
        </>
    )
}
