import { Signal, computed } from "@preact/signals";
import Markdown from 'react-markdown'


export interface FormStatusProps {
    content: Signal<Array<string>>
}

export function FormStatus(props: FormStatusProps) {
    const rawMD = computed(() => {
        if (props.content.value.length == 0) {
            return "Submit the form to see results";
        } else {
            return props.content.value.join("");
        }
    });
    const md = <Markdown>{rawMD.value}</Markdown>
    return (
        <>
            <h5>Results</h5>
            <div id="result">{md}</div>
        </>
    );
}
