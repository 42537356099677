import { App as LoginApp } from '../login/app';
import { ComponentChildren } from 'preact';
import { computed } from '@preact/signals';
import { useAuth } from './auth';

interface ProtectedProps {
    children: ComponentChildren;
    admin: boolean
}

const validUserUIDs = new Set([
    "oOIxc3U084Pz2y9uY10QDENXNmt1", // phranzia@gmail.com
    "iw8zplU5aCSQpzdRIvqO1vM53sB2", // me@arschles.com
    "NozL40e30Pa8vwutbSwxSm2nvK33", // aaron@ecomaz.net
    "tRI2xAlPq9UYZMJ4DsJJipPShSw2", // reenarajcoomar1@gmail.com
]);


export function Protected(props: ProtectedProps): JSX.Element {
    const authSig = useAuth();
    const jsx = computed(() => {
        const { user, loading } = authSig.value;
        if (loading) {
            return <></>;
        }
        if (user === null) {
            return <LoginApp></LoginApp>
        }
        // user is logged in at this point, and the user var is non-null
        if (props.admin) {
            if (validUserUIDs.has(user.uid)) {
                return <>{props.children}</>;
            } else {
                return <>invalid</>;
            }
        } else {
            return <>{props.children}</>
        }
    });
    return jsx.value;
};
