import { JSX, render } from 'preact';
import { App as IndexApp } from "./components/index/app";
import { App as SearchApp } from './components/admin/search/app';
import { App as ResultsApp } from './components/admin/results/app';
import { App as LoginApp } from './components/login/app';
import { App as AdminApp } from "./components/admin/app";
import { Router, Route } from 'preact-router';
import { Protected } from './components/common/auth_protected';

function App() {
    // the first one in this list is treated as the default, below
    const routesInfo: Array<[string, JSX.Element]> = [
        ["/index", <IndexApp />],
        ["/admin", <Protected admin={true}><AdminApp /></Protected>],
        ["/admin/search", <Protected admin={true}><SearchApp /></Protected>],
        ["/admin/results", <Protected admin={true}><ResultsApp /></Protected>],
        ["/login", <LoginApp />],
    ];
    const routesJSX = routesInfo.map((tup, idx) => {
        const [path, jsx] = tup;
        const component = () => jsx;
        if (idx == 0) {
            return <Route path={path} component={component} default />
        } else {
            return <Route path={path} component={component} />
        }
    });

    return (
        <Router>
            {routesJSX}
        </Router>
    );
}

render(<App />, document.getElementById('app')!)
