import { Header } from "../../common/header";
import { Form } from "./form"
import { FormStatus } from "./form_status"
import { Signal, signal } from "@preact/signals"

export function App() {
    const resultSig: Signal<Array<string>> = signal([]);

    return (
        <>
            <Header></Header>
            <div className="container">
                <div className="row">
                    <h2>Prompt experimenter</h2>
                    <div className="col-md-8">
                        <Form resultSig={resultSig} />
                    </div>
                    <div className="col-md-4">
                        <FormStatus content={resultSig} />
                    </div>
                </div>
            </div>
        </>
    )
}
