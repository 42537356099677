import { useState } from 'preact/hooks';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, useAuth } from '../common/auth';

export function App() {
    const authSig = useAuth();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const handleLogin = async (e: Event) => {
        e.preventDefault();
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            authSig.value.user = res.user;
        } catch (err: any) {
            setError(err.message);
        }
    };
    const handleSignup = async (e: Event) => {
        e.preventDefault();
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password)
            authSig.value.user = res.user;
        } catch (err: any) {
            setError(err.message);
        }
    }

    return (
        <>
            <div class="container">
                <h1>Login</h1>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <form>
                    <label for="email">Email</label>
                    <input
                        class="form-control"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
                        placeholder="Email"
                    />
                    <label for="password">Password</label>
                    <input
                        class="form-control"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
                        placeholder="Password"
                    />
                    <button
                        class="btn btn-primary"
                        type="submit"
                        onClick={handleLogin}
                    >Login</button>
                    <button
                        class="btn btn-secondary"
                        type="submit"
                        onClick={handleSignup}
                    >Sign up</button>
                </form>
            </div>
        </>
    );
};
