import { JSX } from "preact/jsx-runtime";
import { Result } from "./results_api";
import { match } from 'ts-pattern';

export type CompletenessMetric = "completeness";

export type ResultMetric = "completeness" | "accuracy" | "understandability" | "citations";

export function valueForMetric(mtc: ResultMetric, res: Result): number | null {
    return match(mtc)
        .with("accuracy", () => res.accuracy_rating)
        .with("completeness", () => res.completeness_rating)
        .with("understandability", () => res.understandable_rating)
        .with("citations", () => res.citations_rating)
        .exhaustive();
}


export function setValueForMetric(mtc: ResultMetric, res: Result, val: number): void {
    switch (mtc) {
        case "accuracy": {
            res.accuracy_rating = val;
            return;
        }
        case "completeness": {
            res.completeness_rating = val;
            return;
        }
        case "understandability": {
            res.understandable_rating = val;
            return;
        }
        case "citations": {
            res.citations_rating = val;
        }
    }
}

export type UpdateResultFn = (num: number) => Promise<void>;

export function resultMetricView(
    mtc: ResultMetric,
    val: number | null,
    updateVal: UpdateResultFn
): JSX.Element {

    const reportGoodButton = val != 1 ? (
        <button
            class="btn btn-success"
            onClick={async () => await updateVal(1)}
        >Good</button>
    ) : (
        <button class="btn btn-light">Good ✅</button>
    );
    const reportBadButton = val != -1 ? (
        <button
            class="btn btn-danger"
            onClick={async () => await updateVal(-1)}
        >Bad</button>
    ) : (
        <button class="btn btn-light">Bad ❌</button>
    );
    return (
        <p class="result-metric">
            <span>{mtc}: </span>
            {reportGoodButton}
            {reportBadButton}
        </p>
    );
}
