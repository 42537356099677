import * as D from 'io-ts/Decoder'
import { isRight } from 'fp-ts/lib/Either'
import { ResultMetric } from './result_metrics';

const NumberOrNull = D.nullable(D.number);

const ResultT = D.struct({
    uid: D.string,
    prefix: D.string,
    question: D.string,
    response: D.string,
    completeness_rating: NumberOrNull,
    accuracy_rating: NumberOrNull,
    understandable_rating: NumberOrNull,
    citations_rating: NumberOrNull,
});

const ResultsT = D.struct({
    "results": D.array(ResultT),
});

export type Result = D.TypeOf<typeof ResultT>;

export async function getResults(): Promise<Array<Result>>{
    try {
        const response = await fetch("/api/results");
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // marking with `unknown` so we're forced to assert a type
        const rawJSON: unknown = await response.json();
        // the returned JSON is an array of results
        const decodeRes = ResultsT.decode(rawJSON);
        if (isRight(decodeRes)) {
            return decodeRes.right.results;
        } else {
            const err = D.draw(decodeRes.left);
            throw new Error(err);
        }
    } catch (error) {
        console.error("Error in getResults:", error);
        throw error;
    }

}

export async function reportResult(res: Result, metric: ResultMetric, ratingVal: number) {
    const url = `/api/results/${res.uid}/${metric.toString()}`
    const body = { "rating_value": ratingVal };
    const resp = await fetch(url, {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    })
    if (!resp.ok) {
        throw new Error("Network response was not OK");
    }
    return;
}
