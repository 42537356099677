import { Signal } from "@preact/signals";

export async function streamResponse(resp: Response, sig: Signal<Array<string>>) {
    if (resp.body) {
        const reader = resp.body.getReader();
        const decoder = new TextDecoder();

        const readChunk = async () => {
            const { done, value } = await reader.read();
            const rawVal = decoder.decode(value, { stream: true });
            console.log(`got rawVal ${rawVal}`);
            const val = rawVal;
            // we need to do an immutable append like this, not with the standard
            // mutating .push() method because we want to broadcast the event
            // indicating that sig.value is updated. calling .push() won't achieve
            // that.
            sig.value = sig.value.concat(val)
            if (done) {
                return;
            }
            await readChunk();
        };

        await readChunk();
    } else {
        const val = "Form submission failed: no response body";
        sig.value.push(val);
    }
}
