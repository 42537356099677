import { Signal } from "@preact/signals";
import { streamResponse } from "./stream_res";

export async function submitForm(
    prefix: string,
    question: string,
    file: File,
    resultSig: Signal<Array<string>>,
) {

    const formData = new FormData();
    formData.append('prefix', prefix);
    formData.append('question', question);
    formData.append('file', file);

    try {
        const response = await fetch("/ask", {
            method: 'POST',
            body: formData
        });
        if (response.ok) {
            resultSig.value = [];
            await streamResponse(response, resultSig);
        } else {
            resultSig.value = [
                `form submission failed: ${await response.text()}`
            ];
            return;
        }
    } catch (error) {
        resultSig.value = [
            `Form submission error: ${error}`
        ];
        return;
    }

}
