import { Signal } from '@preact/signals';
import { JSX } from 'preact';
import { useState } from "preact/hooks";
import { submitForm } from './form_submit';



const prefixPresets = [
    "I have attached a protocol for a clinical trial, and have asked a few questions about it below. Please include an answer to each question. All answers must be suitable for a 6th grader.",
    "I have attached a protocol for a clinical trial, and have asked a few questions about it below. Please include an answer to each question, and include a reference to the page number or numbers in the document from which you got the answer. All answers must be suitable for a 6th grader.'"
];

const questionPresets = [
    'What are the eligibility requirements for this trial?'
];


export interface FormProps {
    resultSig: Signal<Array<string>>;
}


export const Form = (props: FormProps) => {
    const [prefix, setPrefix] = useState("");
    const [question, setQuestion] = useState("");
    const [file, setFile] = useState<File | null>(null);

    const handleSubmit = async (event: JSX.TargetedEvent<HTMLFormElement, Event>) => {
        event.preventDefault();
        props.resultSig.value = ["Submitted, waiting for response..."];

        if (prefix == "") {
            alert("please enter a prefix");
            return;
        }
        else if (question == "") {
            alert("please enter a question");
            return;
        }
        else if (file == null) {
            alert("please enter a file");
            return;
        }

        await submitForm(prefix, question, file, props.resultSig);
    };

    const setPresetPrefix = (idx: number) => {
        const pfx = prefixPresets[idx];
        setPrefix(pfx);
    };

    const handlePrefixChange = (event: JSX.TargetedEvent<HTMLTextAreaElement, Event>) => {
        setPrefix(event.currentTarget.value);
    };

    const setPresetQuestion = (idx: number) => {
        const q = questionPresets[idx];
        setQuestion(q);
    };

    const handleQuestionChange = (event: JSX.TargetedEvent<HTMLTextAreaElement, Event>) => {
        setQuestion(event.currentTarget.value);
    };



    const handleFileChange = async (event: JSX.TargetedEvent<HTMLInputElement, Event>) => {
        const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
        setFile(file);
    };


    return (
        <>
            <form
                method="post"
                action="/ask"
                enctype="multipart/form-data"
                onSubmit={handleSubmit}
            >
                <div class=" row mb-3 border border-primary">
                    <label for="prefix" class="form-label accordion-header">
                        Prefix
                    </label>
                    <textarea
                        rows={5}
                        class="form-control"
                        name="prefix"
                        id="prefix"
                        onChange={handlePrefixChange}
                        value={prefix}
                    >
                    </textarea>
                    <p class="mt-1">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={() => setPresetPrefix(0)}
                        >
                            preset (single question)
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={() => setPresetPrefix(1)}
                        >
                            preset (multi question)
                        </button>
                    </p>
                </div>
                <div class="row mb-3">
                    <label for="question" class="form-label">Question(s)</label>
                    <textarea
                        class="form-control"
                        name="question"
                        id="question"
                        rows={3}
                        value={question}
                        onChange={handleQuestionChange}
                    ></textarea>
                    <p>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={() => setPresetQuestion(0)}
                        >
                            preset 1 (eligibility requirements)
                        </button>
                    </p>

                </div>
                <div class="row mb-3">
                    <label for="file" class="form-label">File</label>
                    <input
                        type='file'
                        name='file'
                        onChange={handleFileChange}
                    />
                </div>
                <div class="row">
                    <button class="btn btn-primary" type="submit">
                        Ask
                    </button>
                </div>
            </form>
        </>
    );
}
