import { useEffect, useState } from "preact/hooks"
import { Result, getResults } from "./results_api"
import { resultEntry } from "./result";
import { Header } from "../../common/header";

export function App() {
    const [resultsList, setResultsList] = useState<Array<Result>>([]);
    useEffect(() => {
        const loadFromServer = async () => {
            const resList = await getResults();
            setResultsList(resList);
        };
        loadFromServer();
    }, []);

    const resultsJSX = resultsList.flatMap(res => {
        if (res === undefined) {
            return [];
        } else {
            return [resultEntry(res)];
        }
    });

    const headers = ["ID", "Prefix", "Question", "Response"].map((hdr) => {
        return <th scope="col">{hdr}</th>;
    })
    return (
        <>
            <Header></Header>
            <div className="container">
                <div className="row">
                    <h2>{resultsList.length} Result(s)</h2>
                </div>
                <table class="table">
                    <thead>
                        <tr>{headers}</tr>
                    </thead>
                    <tbody>
                        {resultsJSX}
                    </tbody>
                </table>
            </div>

        </>
    )
}
