import "./style.css"
import { useState } from "preact/hooks"

export function App() {
    const formName = "waitlist"
    const [email, setEmail] = useState<string | null>(null);
    const formSubmitHandler = async (e: SubmitEvent) => {
        e.preventDefault();
        const resp = await fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify({ "form-name": formName, email }),
        });
        if (!resp.ok) {
            throw new Error(`submitting form failed: ${await resp.text()}`);
        }
    };
    const formJSX = (
        <form
            name={formName}
            id="waitlist-signup"
            onSubmit={async (e) => await formSubmitHandler(e)}
        >
            <div class="form-group">
                <input
                    type="email"
                    name="email"
                    class="form-control montserrat-font"
                    id="inputEmail"
                    aria-describedby="email address"
                    placeholder="EMAIL ADDRESS"
                    onChange={(e) => setEmail(e.currentTarget.value)}
                />
            </div>
            <div>
                <button type="submit" class="montserrat-font" id="waitlist-button2">
                    <b>Join the Waitlist</b>
                </button>
            </div>
        </form>
    )

    return (
        <>
            <div class="parallax">
                <nav class="navbar">
                    <a href="/" class="navbar-brand playfair-display">
                        <img src="/images/pLogo.png" class="playfair-display" alt="Phays Logo" id="logoImage" />
                        phays
                    </a>
                </nav>
                {/* <!-- <div class="container"> --> */}
                {/* <!-- <div class="col-md-7"> --> */}
                <div class="playfair-display" id="top-subheading">
                    Supporting Patients and Research Teams at Every Phase
                </div>
                <div id="top-description">
                    <p class="montserrat-font">Phays is designed to enhance patient support and engagement while expanding the
                        operational capabilities of clinical research teams
                    </p>
                </div>
                <div id="waitlist-button-div">
                    <a class="waitlist-button montserrat-font" href="#waitlist-signup">
                        <b>Join the Waitlist</b>
                    </a>
                </div>
                {/* <!-- <div class="col-md-1">
                            <div class="image-fade">
                                <img src="/images/pLogo.jpg" alt="Image 1" class="animated-image" style="animation-delay: 0s;">
                                    <img src="/images/dna.svg" alt="Image 2" class="animated-image" style="animation-delay: 4s;">
                                        <img src="/images/girl.svg" alt="Image 3" class="animated-image" style="animation-delay: 8s;">
                                        </div>
                                        <div>
                                        </div> --> */}
                {/* <!-- <div class="video-background">
                            <video autoplay muted loop id="myVideo">
                                <source src="/images/sample.mp4" type="video/mp4">
                                    Your browser does not support HTML5 video.
                            </video>
                        </div> --> */}
                <div class="container" id="stats-container">
                    <div class="row justify-content-center">
                        <div class="col-md-1 playfair-display stats blurred-shadow">
                            75%
                        </div>
                        <div class="col-md-4">
                            <p class="montserrat-font" id="stat1-text">
                                of researchers found <b>digital tools helpful</b> for
                                clinical trial process completion
                            </p>
                        </div>
                        <div class="col-md-1 playfair-display stats blurred-shadow">
                            52%
                        </div>
                        <div class="col-md-4">
                            <p class="montserrat-font" id="stat2-text">of <b>patients prefer using a
                                digital tool</b> instead of interacting with HCPs
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container" id="highlights-container">
                    <div class="row justify-content-center">
                        <div class="col-md-3">
                            <h1 class="montserrat-font" id="highlights-heading">
                                <b>The Highlights</b>
                            </h1>
                        </div>
                        <div class="col-md-9">
                            <div id="overflowTest">
                                <div class="highlightEachFeature">
                                    <div class="row justify-content-center">
                                        <img src="/images/girl.svg" id="highlightsImage1" />
                                        {/* <!-- Illustrations from here - https://www.figma.com/community/file/1071151296679851124/570-abstract-illustrations-bangalore-vector-illustrations-set-svg-png?searchSessionId=luqlfjix-6t03wm96ykv --> */}
                                    </div>
                                    <div class="row">
                                        <p class="playfair-display highlight-description">Clinical trials can often
                                            feel overwhelming. <b class="boldColortext">Phays simplifies the process</b> by helping patients
                                            easily keep track of their trial activities while staying engaged.
                                        </p>
                                    </div>
                                </div>
                                <div class="highlightEachFeature">
                                    <div class="row justify-content-center">
                                        <img src="/images/chartsAndgraphs.svg" id="highlightsImage1" />
                                    </div>
                                    <div class="row">
                                        <p class="playfair-display highlight-description">Data is powerful, but only
                                            if we can understand and interpret it correctly. Phays is designed to
                                            assist research teams <b class="boldColortext">transform complex information
                                                into understandable narratives</b>.
                                        </p>
                                    </div>
                                </div>
                                <div class="highlightEachFeature">
                                    <div class="row justify-content-center">
                                        <img src="/images/dna.svg" id="highlightsImage1" />
                                    </div>
                                    <div class="row">
                                        <p class="playfair-display highlight-description">Science and medicine are advancing
                                            rapidly. Phays streamlines research activities, <b class="boldColortext">allowing teams
                                                to focus on the critical elements</b> crucial for developing the best treatments for
                                            patients.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" id="joinUsContainer">
                    <div id="joinUsContent">
                        <h2 class="playfair-display"><b>Join us in transforming research.</b></h2>
                        <p class="montserrat-font">Get the latest updates and happenings at Phays.</p>
                        {formJSX}
                        <div id="successMessage" style="display:none;">All set! Your information was submitted successfully.</div>
                    </div>
                </div>
                <footer class="footer">
                    <div class="container" id="footerContainer">
                        <div class="row">
                            <div class="col-md-4 montserrat-font">
                                <a href="/" class="navbar-brand playfair-display">
                                    <img src="/images/pLogo.png" class="playfair-display" alt="Phays Logo" id="logoImageFooter" />
                                    <label id="phaysFooter">phays</label>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <p class="montserrat-font footerHeadings">ABOUT</p>
                                <a class="montserrat-font footerLinks" href="#highlights-heading">
                                    The Highlights
                                </a>
                            </div>
                            <div class="col-md-3">
                                <p class="montserrat-font footerHeadings">CONTACT</p>
                                <a class="montserrat-font footerLinks" href="#waitlist-signup">
                                    Join the Waitlist
                                </a>
                                <a class="montserrat-font footerLinks" href="mailto:hello@phays.co">
                                    Email
                                </a>
                            </div>
                            <div class="col-md-3">
                                {/* <!-- other tags go here --> */}
                            </div>
                        </div>
                    </div>
                </footer>
                <div class="montserrat-font" id="copyright">Copyright © 2024 Phays</div>
            </div>
        </>
    )
}
