import { JSX } from "preact/jsx-runtime";
import { Result, reportResult } from "./results_api";
import Markdown from "react-markdown";
import { ResultMetric, resultMetricView, setValueForMetric, valueForMetric } from "./result_metrics";
import { Dispatch, StateUpdater, useState } from "preact/hooks";


export function resultEntry(res: Result): JSX.Element {
    const resultMetricNames: Array<ResultMetric> = [
        "completeness",
        "accuracy",
        "understandability",
        "citations",
    ];
    type MetricAndState = [
        ResultMetric,
        number | null,
        Dispatch<StateUpdater<number | null>>
    ];

    const metrics: Array<MetricAndState> = resultMetricNames.map((metricName) => {
        const [getter, setter] = useState<number | null>(
            valueForMetric(metricName, res)
        );
        return [metricName, getter, setter];
    });
    const metricViews = metrics.map((tup) => {
        const [metricName, getter, setter] = tup;
        return resultMetricView(
            metricName,
            getter,
            async (val: number) => {
                setter(val)
                setValueForMetric(metricName, res, val);
                await reportResult(res, metricName, val);
            }
        );
    })
    return (
        <tr>
            <th scope="row">{res.uid}</th>
            <td>{res.prefix}</td>
            <td>{res.question}</td>
            <td><Markdown>{res.response}</Markdown></td>
            <td>
                {metricViews}
            </td>
        </tr>
    );
}
