import { signOut } from "firebase/auth";
import { useAuth } from "./auth";
import { route } from "preact-router";

export function Header() {
    const linkPaths = [
        ["/admin/search", "Search"],
        ["/admin/results", "Results"]
    ];
    const linksJSX = linkPaths.map((tup) => {
        const [link, txt] = tup;
        return (
            <li class="nav-item">
                <a class="nav-link" href={link}>{txt}</a>
            </li>
        );
    });
    const authSig = useAuth();
    if (authSig.value.user !== null) {
        const handleSignOut = async () => {
            await signOut(authSig.value.auth);
            route(`/`, true);
        };
        linksJSX.push((
            <li class="nav-item">
                <button
                    class="nav-link btn btn-link"
                    onClick={async () => await handleSignOut()}
                >Logout {authSig.value.user.email}</button>
            </li >
        ))
    }


    return (
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        {linksJSX}
                    </ul>
                </div>
            </div>
        </nav>
    )
}
